import React from 'react'
import './stylesheets/meeting.scss'
import MenuModal from '../components/MenuModal'
import {Button} from 'rsuite'
import Iframe from 'react-iframe'
import FindBook from '../components/constant'
import logo from '../assets/trans.png'
// import ModelViewer from 'react-model-viewer';

class Meeting extends React.Component{

    state = {
        loading : true,
        showModal : true,
        book : 'https://poly.google.com/view/dUDndg_qWlv/embed?chrome=min'
    }


    componentDidMount() {
        navigator.mediaDevices.getUserMedia({ audio: true, video: true})
      .then(function(stream) {
        console.log('You let me use your mic!')
      })
      .catch(function(err) {
        console.log('No mic for you!')
      });
    }
    toggleModal = () =>{

        let current = this.state.showModal
        this.setState({
            showModal : !current
        })
    }

    select = (value) => {

        
        this.setState({
          book : FindBook(value)
        }, () => {

            document.getElementsByClassName("rs-picker-menu rs-picker-cascader-menu rs-picker-inline")[0].scrollTo(1000,0)
            document.getElementsByClassName("rs-picker-menu rs-picker-cascader-menu rs-picker-inline")[0].scrollTo(1000,0)
        })
      
      console.log(value);
    }

    render(){
        return(
            <div className="meeting-container">
                <div className = "meeting-content">

                    <div className="ag-header">
                        <div className="ag-header-content">
                            <img alt = "logo" id = "logo" src = {logo} />
                            <Button id = "menu-toggle" onClick = {this.toggleModal}> Meniu Lecții </Button>
                        </div>
                    </div>

                    <div className="ag-main">
  
                        <MenuModal select = {this.select} showModal = {this.state.showModal} close = {this.toggleModal} />
    
                        <div className = "frame">
                            <Iframe url= {this.state.book}//"https://poly.google.com/view/b0BZCusbzdf/embed?chrome=min"//{stringURL}
                                width="100%"
                                height="88%"
                                style = {{marginBottom: "20px"}}
                                id="myId"
                                allowFullScreen 
                                className="myClassname"
                                display="initial"
                                allow="camera; microphone"
                               
                                position="relative"/>
                        </div>

                        <div className = "frame">
                            <Iframe url= "https://meet.jit.si/"//"https://poly.google.com/view/b0BZCusbzdf/embed?chrome=min"//{stringURL}
                                width="100%"
                                height="88%"
                                style = {{marginBottom: "20px"}}
                                id="myId"
                                allowFullScreen 
                                className="myClassname"
                                display="initial"
                                allow="midi; microphone; camera"
                               
                                position="relative"/>
                        </div>
                       

                    </div>
                    <div className="ag-footer">
                        <div className="ag-footer-content">
                            <a target = "_blanc" href="https://www.univrse.ro/">
                                <p>Powered By Univrse</p>
                            </a>
                            <p>Contact: 0740 501 061</p>
                        </div>
                        
                    </div>
                </div>
            </div>
        )
    }
}

export default Meeting;