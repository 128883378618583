import React from 'react'
import {Button, SelectPicker} from 'rsuite'
import { Drawer, Cascader } from 'rsuite';
import findBook from './books'
import "./stylesheets/menu-modal.css"

class MenuModal extends React.Component{
    state = {
        response : "",
        type : "vr",
        data : [{
          "value": "1000",
          "label": "Manuale Scolare",
          "children": findBook(),
        },
        {
          "value": "1001",
          "label": "Lectii VR",
          "children": [
            {
              "value": "1002",
              "label": "Biologie",
              "children" : [{
                "value": "1002-0",
                "label": "Ochiul Uman",
              },
              {
                "value": "1002-1",
                "label": "Sistemul Respirator",
              },
              {
                "value": "1002-2",
                "label": "Sistemul Muscular",
              },
              {
                "value": "1002-3",
                "label": "Sarcina",
              }
              ]
            },
            {
              "value": "1003",
              "label": "Geografie",
              "children" : [{
                "value": "1003-0",
                "label": "Maya – Chichén Itzá",
              },
              {
                "value": "1003-1",
                "label": "Paris",
              },
              {
                "value": "1003-2",
                "label": "Marele Zid Chinezesc",
              },
              {
                "value": "1003-3",
                "label": "Petra",
              },
              {
                "value": "1003-4",
                "label": "Sistemul Solar",
              },
              {
                "value": "1003-5",
                "label": "Apele Continentale",
              },
              {
                "value": "1003-6",
                "label": "Cele 7 Minuni ale Lumii Moderne",
              },
              {
                "value": "1003-7",
                "label": "Lectie Astronomie",
              },
              {
                "value": "1003-8",
                "label": "Polul Nord",
              }]
            },
            {
              "value": "1004",
              "label": "Stiinte",
              "children" : [
                {
                  "value": "1004-0",
                  "label": "Fotosinteza",
                },
                {
                  "value": "1004-1",
                  "label": "Dinozauri",
                },
                {
                  "value": "1004-2",
                  "label": "Virusuri",
                },
                
              ]
            },
          
          ]
        }],
        dataBooks : findBook(),
        dataVR : [
            {
              "value": "1002",
              "label": "Biologie",
              "children" : [{
                "value": "1002-0",
                "label": "Ochiul Uman",
              },
              {
                "value": "1002-1",
                "label": "Sistemul Respirator",
              },
              {
                "value": "1002-2",
                "label": "Sistemul Muscular",
              },
              {
                "value": "1002-3",
                "label": "Sarcina",
              }
              ]
            },
            {
              "value": "1003",
              "label": "Geografie",
              "children" : [{
                "value": "1003-0",
                "label": "Maya – Chichén Itzá",
              },
              {
                "value": "1003-1",
                "label": "Paris",
              },
              {
                "value": "1003-2",
                "label": "Marele Zid Chinezesc",
              },
              {
                "value": "1003-3",
                "label": "Petra",
              },
              {
                "value": "1003-4",
                "label": "Sistemul Solar",
              },
              {
                "value": "1003-5",
                "label": "Apele Continentale",
              },
              {
                "value": "1003-6",
                "label": "Cele 7 Minuni ale Lumii Moderne",
              },
              {
                "value": "1003-7",
                "label": "Lectie Astronomie",
              }]
            },
            {
              "value": "1004",
              "label": "Stiinte",
              "children" : [
                {
                  "value": "1004-0",
                  "label": "Fotosinteza",
                },
                {
                  "value": "1004-1",
                  "label": "Dinozauri",
                },
                {
                  "value": "1004-2",
                  "label": "Virusuri",
                },
                
              ]
            },
          
          ]
            
        }

        handleTypeSelect = (value) => {

          this.setState({
            type : value
          })
        }
    handleChange = (value, event, id) => {

      // if ( value.value[0] === '5' || value.value[0] === '6' || value.value[0] === '7'){
      //   document.getElementsByClassName("rs-picker-menu rs-picker-cascader-menu rs-picker-inline")[0].scrollTo(1000,0)
      //   document.getElementsByClassName("rs-picker-menu rs-picker-cascader-menu rs-picker-inline")[0].scrollTo(1000,0)


      // }

      // if ( value.value[1] === '5' || value.value[1] === '6' || value.value[1] === '7'){
        


      // }

        console.log(value)

    }

    sendResponse = () =>{
        this.props.close();
        // toast.success("Carte deschisa!")
    }
    render(){
        let cascader = <Cascader onChange = {this.props.select} searchable = {false} inline   placeholder = "Selecteaza lectia" block data={this.state.data} menuWidth={200}/>

        if (window.innerWidth < 700){
            cascader = (
            <div style = {{width:"95%", margin:"auto", display: "flex", flexDirection: "column", marginTop: "20px"}}>
                <SelectPicker value = {this.state.type} searchable = {false} data = {[{label: "Manuale Scolare", value : "manuale"}, {label: "Lectii VR", value : "vr"}]} onChange = {this.handleTypeSelect} />
                <Cascader parentSelectable style = {{marginTop: "10px"}} onSelect = {this.handleChange} onChange = {this.props.select} searchable = {false} inline   placeholder = "Selecteaza lectia" block data={this.state.type === "vr"?this.state.dataVR:this.state.dataBooks} menuWidth={150}/>
            </div>
            
            )

        }
        return(
            <Drawer
                show={this.props.showModal}
                onHide={this.props.close}
                size = {window.innerWidth < 700?"lg":"md"}
                placement = {window.innerWidth < 700?"top":"right"}
                >
                <Drawer.Header>
                    <Drawer.Title>Meniu</Drawer.Title>
                </Drawer.Header>
                <Drawer.Body>
                    <h4 style = {{marginTop: "30px"}}> Alegeți lecția dorită </h4>
                    {cascader}

                    <Button style = {{marginTop: "20px", backgroundColor: "#EC1651", color:'white"'}}appearance = "primary" onClick = {this.sendResponse}> Începe Lecția </Button>
                    <p style ={{fontSize: "10px"}}> *Continutul educational folosit in aplicatie este oferit cu ajutorul platformelor <a href="edu.ro">edu.ro</a> si <a href ="poly.google.com">poly.google.com</a> </p>
                </Drawer.Body>
            </Drawer>
        )
    }
}

export default MenuModal;