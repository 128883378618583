let constantBooks = [{
    "value": "1",
    "label": "Limba si literatura romana",
    "children": [
      {
        "label": "Clasa a V-a",
        "value": "15",
        "children": [
          {
            "value": "15-0",
            "label": "INTUITEXT"
          },
          {
            "value": "15-1",
            "label": "SIGMA"
          },
          {
            "value": "15-2",
            "label": "CORINT"
          },
          {
            "value": "15-3",
            "label": "LITERA"
          },
          {
            "value": "15-4",
            "label": "ARS LIBRI PROF"
          },
          {
            "value": "15-5",
            "label": "CD PRESS"
          },
          {
            "value": "15-6",
            "label": "ARAMIS PRINT"
          },
          {
            "value": "15-7",
            "label": "EDP"
          },
          {
            "value": "15-8",
            "label": "PARALELA 45"
          },
          {
            "value": "15-9",
            "label": "BOOKLET"
          },
          {
            "value": "15-10",
            "label": "ART"
          },
        ]
      },
      {
        "label": "Clasa a VI-a",
        "value": "16",
        "children": [
          {
            "value": "16-0",
            "label": "EDP"
          },
          {
            "value": "16-1",
            "label": "EDP2"
          }
        ]
      },
      {
        "label": "Clasa a VII-a",
        "value": "17",
        "children": [
          {
            "value": "17-0",
            "label": "ART KLEET"
          },
          {
            "value": "17-1",
            "label": "ARAMIS PRINT"
          },
          {
            "value": "17-2",
            "label": "INTUITEXT"
          }
        ]
      },
      
    ]
},
{
    "value": "2",
    "label": "Matematica",
    "children": [
      {
        "label": "Clasa a V-a",
        "value": "25",
        "children": [
          {
            "value": "25-0",
            "label": "INTUITEXT"
          },
          {
            "value": "25-1",
            "label": "CORINT"
          },
          {
            "value": "25-2",
            "label": "ART"
          },
          {
            "value": "25-3",
            "label": "IDEE"
          },
          {
            "value": "25-4",
            "label": "CD PRESS"
          },
          {
            "value": "25-5",
            "label": "SIGMA"
          },
          {
            "value": "25-6",
            "label": "ARAMIS PRINT"
          }
        ]
      },
      {
        "label": "Clasa a VI-a",
        "value": "26",
        "children": [
          {
            "value": "26-0",
            "label": "EDP"
          },
          {
            "value": "26-1",
            "label": "EDP2"
          },
        ]
      },
      {
        "label": "Clasa a VII-a",
        "value": "27",
        "children": [
          {
            "value": "27-0",
            "label": "SIGMA"
          },
          {
            "value": "27-1",
            "label": "LITERA"
          },
          {
            "value": "27-2",
            "label": "INTUITEXT"
          }
        ]
      },
    ]},
    {
        "value": "3",
        "label": "Biologie",
        "children": [
          {
            "label": "Clasa a V-a",
            "value": "35",
            "children": [
              {
                "value": "35-0",
                "label": "DIDACTICA PUBLISHING HOUSE"
              },
              {
                "value": "35-1",
                "label": "ARAMIS PRINT"
              },
              {
                "value": "35-2",
                "label": "SIGMA"
              },
              {
                "value": "35-3",
                "label": "ARS LIBRI PROF"
              },
              {
                "value": "35-4",
                "label": "Biologie Corint"
              },
              {
                "value": "35-5",
                "label": "TAMAR PRINT"
              },
              {
                "value": "35-6",
                "label": "ART"
              },
              {
                "value": "35-7",
                "label": "EDP"
              },
              {
                "value": "35-8",
                "label": "INTUITEXT"
              },
              {
                "value": "35-9",
                "label": "LITERA"
              }
            ]
          },
          {
            "label": "Clasa a VI-a",
            "value": "36",
            "children": [
              {
                "value": "36-0",
                "label": "EDP"
              },
              {
                "value": "36-1",
                "label": "EDP2"
              }
            ]
          },
          {
            "label": "Clasa a VII-a",
            "value": "37",
            "children": [
              {
                "value": "37-0",
                "label": "ARS LIBRI PROF"
              },
              {
                "value": "37-1",
                "label": "LITERA"
              },
              {
                "value": "37-2",
                "label": "DIDACTICA PUBLISHING HOUSE"
              }
            ]
          }
        ]},
        {
            "value": "4",
            "label": "Geografie",
            "children": [
              {
                "label": "Clasa a V-a",
                "value": "45",
                "children": [
                  {
                    "value": "45-0",
                    "label": "ARAMIS PRINT"
                  },
                  {
                    "value": "45-1",
                    "label": "CORINT"
                  },
                  {
                    "value": "45-2",
                    "label": "EDP"
                  },
                  {
                    "value": "45-3",
                    "label": "ART"
                  },
                  {
                    "value": "45-4",
                    "label": "CD PRESS"
                  },
                  {
                    "value": "45-5",
                    "label": "LITERA"
                  },
                ]
              },
              {
                "label": "Clasa a VI-a",
                "value": "46",
                "children": [
                  {
                    "value": "46-0",
                    "label": "EDP"
                  },
                ]
              },
              {
                "label": "Clasa a VII-a",
                "value": "47",
                "children": [
                  {
                    "value": "47-0",
                    "label": "ART KLEET"
                  },
                  {
                    "value": "47-1",
                    "label": "CD PRESS"
                  },
                  {
                    "value": "47-2",
                    "label": "AVANTGARDE CENTER"
                  }
                ]
              }
            ]},

            {
                "value": "5",
                "label": "Istorie",
                "children": [
                  {
                    "label": "Clasa a V-a",
                    "value": "55",
                    "children": [
                      {
                        "value": "55-0",
                        "label": "LITERA"
                      },
                      {
                        "value": "55-1",
                        "label": "EDP"
                      },
                      {
                        "value": "55-2",
                        "label": "Corint 1"
                      },
                      {
                        "value": "55-3",
                        "label": "Corint 2"
                      },
                      {
                        "value": "55-4",
                        "label": "CD PRESS"
                      },
                      {
                        "value": "55-5",
                        "label": "All"
                      },
                      {
                        "value": "55-6",
                        "label": "ARAMIS PRINT"
                      },
                    ]
                  },
                  {
                    "label": "Clasa a VI-a",
                    "value": "56",
                    "children": [
                      {
                        "value": "56-0",
                        "label": "EDP"
                      },
                    ]
                  },
                  {
                    "label": "Clasa a VII-a",
                    "value": "57",
                    "children": [
                      {
                        "value": "57-0",
                        "label": "ART KLEET"
                      },
                      {
                        "value": "57-1",
                        "label": "CD PRESS"
                      },
                      {
                        "value": "57-2",
                        "label": "ARAMIS PRINT"
                      }
                    ]
                  }
                ]},
                {
                    "value": "6",
                    "label": "Limba Engleza",
                    "children": [
                      {
                        "label": "Clasa a V-a",
                        "value": "65",
                        "children": [
                          {
                            "value": "65-0",
                            "label": "Art"
                          },
                          {
                            "value": "65-1",
                            "label": "UNISCAN"
                          },
                          {
                            "value": "65-2",
                            "label": "LITERA"
                          },
                          {
                            "value": "65-3",
                            "label": "LITERA"
                          },
                          {
                            "value": "65-4",
                            "label": "UNISCAN1"
                          },
                          {
                            "value": "65-5",
                            "label": "ART1"
                          },
                          {
                            "value": "65-6",
                            "label": "Booklet"
                          },
                          {
                            "value": "65-7",
                            "label": "LITERA1"
                          },
                          {
                            "value": "65-8",
                            "label": "CORINT"
                          },
                        ]
                      },
                      {
                        "label": "Clasa a VI-a",
                        "value": "66",
                        "children": [
                          {
                            "value": "66-0",
                            "label": "ART KLEET-INTENSIV"
                          },
                          {
                            "value": "66-1",
                            "label": "UNISCAN 2"
                          },
                          {
                            "value": "66-2",
                            "label": "UNISCAN -INTENSIV"
                          },
                          {
                            "value": "66-3",
                            "label": "LITERA"
                          },
                          {
                            "value": "66-4",
                            "label": "LITERA-INTENSIV"
                          },
                          {
                            "value": "66-5",
                            "label": "UNISCAN -INTENSIV"
                          },
                          {
                            "value": "66-6",
                            "label": "ART KLEET"
                          },
                          {
                            "value": "66-7",
                            "label": "UNISCAN 1"
                          },

                        ]
                      },
                      {
                        "label": "Clasa a VII-a",
                        "value": "67",
                        "children": [
                          {
                            "value": "67-0",
                            "label": "ART KLEET-INTENSIV"
                          },
                          {
                            "value": "67-1",
                            "label": "UNISCAN -INTENSIV"
                          },
                          {
                            "value": "67-2",
                            "label": "LITERA INTENSIV"
                          },
                          {
                            "value": "67-3",
                            "label": "LITERA 1"
                          },
                          {
                            "value": "67-4",
                            "label": "UNISCAN 1"
                          },
                          {
                            "value": "67-5",
                            "label": "ART KLEET"
                          },
                          {
                            "value": "67-6",
                            "label": "UNISCAN 2"
                          },
                          {
                            "value": "67-7",
                            "label": "SITKA"
                          },
                        ]
                      }
                    ]},
                    {
                        "value": "7",
                        "label": "Informatica si TIC",
                        "children": [
                          {
                            "label": "Clasa a V-a",
                            "value": "75",
                            "children": [
                              {
                                "value": "75-0",
                                "label": "SIGMA"
                              },
                              {
                                "value": "75-1",
                                "label": "CORINT"
                              },
                              {
                                "value": "75-2",
                                "label": "INTUITEXT"
                              },
                              {
                                "value": "75-3",
                                "label": "EDP"
                              },
                              {
                                "value": "75-4",
                                "label": "LITERA"
                              },
                              {
                                "value": "75-5",
                                "label": "CD PRESS"
                              },
                            ]
                          },
                          {
                            "label": "Clasa a VI-a",
                            "value": "76",
                            "children": [
                              {
                                "value": "76-0",
                                "label": "EDP"
                              },
                              {
                                "value": "76-1",
                                "label": "EDP2"
                              },
                              {
                                "value": "76-2",
                                "label": "EDP3"
                              }
                            ]
                          },
                          {
                            "label": "Clasa a VII-a",
                            "value": "77",
                            "children": [
                              {
                                "value": "77-0",
                                "label": "LITERA"
                              },
                              {
                                "value": "77-1",
                                "label": "INTUITEXT"
                              },
                              {
                                "value": "77-2",
                                "label": "EDP"
                              }
                            ]
                          }
                        ]},



]

const findBook = () => {

return constantBooks;
}

export default findBook;