import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css'; 
import './App.css';
import Meeting from './pages/Meeting'

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <Router>
          <ToastContainer width = "50%" position="top-right"/>
          <Route exact path="/" component={Meeting} />
        </Router>
      </header>
    </div>
  );
}

export default App;
