let values = {

    "1002-0" : "https://poly.google.com/view/dUDndg_qWlv/embed?chrome=min",
    "1002-1" : "https://poly.google.com/view/7fhqcyAB8Lu/embed?chrome=min",
    "1002-2" : "https://poly.google.com/view/8EMNDVa8q34/embed?chrome=min",
    "1002-3" : "https://poly.google.com/view/eowx_ggFbrn/embed?chrome=min",
    "1003-0" : "https://poly.google.com/view/81VrOrohdiN/embed?chrome=min",
    "1003-1" : "https://poly.google.com/view/1uqss18zsQi/embed?chrome=min",
    "1003-2" : "https://poly.google.com/view/04V23gAel3Q/embed?chrome=min",
    "1003-3" : "https://poly.google.com/view/17_cBk5LOTS/embed?chrome=min",
    "1003-4" : "https://poly.google.com/view/5f-5RF6jHpF/embed?chrome=min",
    "1003-5" : "https://poly.google.com/view/eSqMzi40kFM/embed?chrome=min",
    "1003-6" : "https://poly.google.com/view/e41mspENpSz/embed?chrome=min",
    "1003-7" : "https://poly.google.com/view/8YajH3b3Cx6/embed?chrome=min",
    "1003-8" : "https://poly.google.com/view/9vNi0w5Htoz/embed?chrome=min",
    "1004-0" : "https://poly.google.com/view/arT6eG9bj8l/embed?chrome=min",
    "1004-1" : "https://poly.google.com/view/81MlBi6ny-i/embed?chrome=min",
    "1004-2" : "https://poly.google.com/view/bYk-l89ozUd/embed?chrome=min",









    //carti
    "15-0" : "https://manuale.edu.ro/manuale/Clasa%20a%20V-a/Limba%20si%20literatura%20romana/INTUITEXT/",
    "15-1" : "https://manuale.edu.ro/manuale/Clasa%20a%20V-a/Limba%20si%20literatura%20romana/SIGMA/",
    "15-2" : "https://manuale.edu.ro/manuale/Clasa%20a%20V-a/Limba%20si%20literatura%20romana/CORINT/",
    "15-3" : "https://manuale.edu.ro/manuale/Clasa%20a%20V-a/Limba%20si%20literatura%20romana/Litera/",
    "15-4" : "https://manuale.edu.ro/manuale/Clasa%20a%20V-a/Limba%20si%20literatura%20romana/Ars%20Libri/",
    "15-5" : "https://manuale.edu.ro/manuale/Clasa%20a%20V-a/Limba%20si%20literatura%20romana/CD%20PRESS/",
    "15-6" : "https://manuale.edu.ro/manuale/Clasa%20a%20V-a/Limba%20si%20literatura%20romana/Aramis/",
    "15-7" :  "https://manuale.edu.ro/manuale/Clasa%20a%20V-a/Limba%20si%20literatura%20romana/EDP/"  ,
    "15-8" : "https://manuale.edu.ro/manuale/Clasa%20a%20V-a/Limba%20si%20literatura%20romana/Paralela%2045/",
    "15-9" : "https://manuale.edu.ro/manuale/Clasa%20a%20V-a/Limba%20si%20literatura%20romana/Booklet/",
    "15-10" : "https://manuale.edu.ro/manuale/Clasa%20a%20V-a/Limba%20si%20literatura%20romana/ART/#book/00",


    "16-0"	: "https://manuale.edu.ro/manuale/Clasa%20a%20VI-a/Limba%20si%20literatura%20romana/EDP/",
    "16-1" : "https://manuale.edu.ro/manuale/Clasa%20a%20VI-a/Limba%20si%20literatura%20romana/EDP2/",

    "17-0" : "https://manuale.edu.ro/manuale/Clasa%20a%20VII-a/Limba%20si%20literatura%20romana/U0MgQVJUIEtMRVRUIFNS/#book/0-help",
    "17-1" : "https://manuale.edu.ro/manuale/Clasa%20a%20VII-a/Limba%20si%20literatura%20romana/U0MgQVJBTUlTIFBSSU5U/",
    "17-2": "https://manuale.edu.ro/manuale/Clasa%20a%20VII-a/Limba%20si%20literatura%20romana/U0MgSU5UVUlURVhUIFNS/",


    "25-0" :	"https://manuale.edu.ro/manuale/Clasa%20a%20V-a/Matematica/Intuitext/",
    "25-1" :	"https://manuale.edu.ro/manuale/Clasa%20a%20V-a/Matematica/Corint/",
    "25-2" :	"https://manuale.edu.ro/manuale/Clasa%20a%20V-a/Matematica/ART/",
    "25-3" : "https://manuale.edu.ro/manuale/Clasa%20a%20V-a/Matematica/IDEE/",
    "25-4" :	"https://manuale.edu.ro/manuale/Clasa%20a%20V-a/Matematica/CD%20PRESS/",
    "25-5"	: "https://manuale.edu.ro/manuale/Clasa%20a%20V-a/Matematica/Sigma/",
    "25-6": "https://manuale.edu.ro/manuale/Clasa%20a%20V-a/Matematica/Aramis/",

    "26-0" :	"https://manuale.edu.ro/manuale/Clasa%20a%20VI-a/Matematica/EDP/",
    "26-1" : "https://manuale.edu.ro/manuale/Clasa%20a%20VI-a/Matematica/EDP2/",
    
    "27-0"	: "https://manuale.edu.ro/manuale/Clasa%20a%20VII-a/Matematica/U0MgU0lHTUEgU1JM/",
"27-1" :	"https://manuale.edu.ro/manuale/Clasa%20a%20VII-a/Matematica/U0MgR1JVUCBFRElUT1JJ/",
"27-2" :	"https://manuale.edu.ro/manuale/Clasa%20a%20VII-a/Matematica/U0MgSU5UVUlURVhUIFNS/",


"35-0": 	"https://manuale.edu.ro/manuale/Clasa%20a%20V-a/Biologie/Didactica%20Publishing%20House/",
"35-1" :	"https://manuale.edu.ro/manuale/Clasa%20a%20V-a/Biologie/Aramis/",
"35-2":	"https://manuale.edu.ro/manuale/Clasa%20a%20V-a/Biologie/Sigma/",
"35-3":	"https://manuale.edu.ro/manuale/Clasa%20a%20V-a/Biologie/Ars%20Libri/",
"35-4":	"https://manuale.edu.ro/manuale/Clasa%20a%20V-a/Biologie/Corint/",
"35-5":	"https://manuale.edu.ro/manuale/Clasa%20a%20V-a/Biologie/Tamar%20Print/",
"35-6":	"https://manuale.edu.ro/manuale/Clasa%20a%20V-a/Biologie/Art/",
"35-7":	"https://manuale.edu.ro/manuale/Clasa%20a%20V-a/Biologie/EDP/",
"35-8":	"https://manuale.edu.ro/manuale/Clasa%20a%20V-a/Biologie/Intuitext/",
"35-9" :"https://manuale.edu.ro/manuale/Clasa%20a%20V-a/Biologie/Litera/",


"36-0":	"https://manuale.edu.ro/manuale/Clasa%20a%20VI-a/Biologie/EDP/",
"36-1":	"https://manuale.edu.ro/manuale/Clasa%20a%20VI-a/Biologie/EDP2/",
"37-0"	:"https://manuale.edu.ro/manuale/Clasa%20a%20VII-a/Biologie/U0MgQVJTIExJQlJJIFBS/",
"37-1"	:"https://manuale.edu.ro/manuale/Clasa%20a%20VII-a/Biologie/U0MgR1JVUCBFRElUT1JJ/",
"37-2"	:"https://manuale.edu.ro/manuale/Clasa%20a%20VII-a/Biologie/U0MgRElEQUNUSUNBIFBV/",

"45-0":	"https://manuale.edu.ro/manuale/Clasa%20a%20V-a/Geografie/Aramis/",
"45-1":	"https://manuale.edu.ro/manuale/Clasa%20a%20V-a/Geografie/CORINT/",
"45-2":	"https://manuale.edu.ro/manuale/Clasa%20a%20V-a/Geografie/EDP/",
"45-3":	"https://manuale.edu.ro/manuale/Clasa%20a%20V-a/Geografie/ART/",
"45-4":	"https://manuale.edu.ro/manuale/Clasa%20a%20V-a/Geografie/CD%20PRESS/",
"45-5":	"https://manuale.edu.ro/manuale/Clasa%20a%20V-a/Geografie/Litera/",

"46-0":	"https://manuale.edu.ro/manuale/Clasa%20a%20VI-a/Geografie/EDP/",

"47-0": "https://manuale.edu.ro/manuale/Clasa%20a%20VII-a/Geografie/U0MgQVJUIEtMRVRUIFNS/#book/00",
"47-1"	:"https://manuale.edu.ro/manuale/Clasa%20a%20VII-a/Geografie/U0MgQ0QgUFJFU1MgU1JM/",
"47-2"	:"https://manuale.edu.ro/manuale/Clasa%20a%20VII-a/Geografie/U0MgQVZBTlRHQVJERSBD/",

"55-0":	"https://manuale.edu.ro/manuale/Clasa%20a%20V-a/Istorie/Litera/",
"55-1":	"https://manuale.edu.ro/manuale/Clasa%20a%20V-a/Istorie/EDP/",
"55-2":	"https://manuale.edu.ro/manuale/Clasa%20a%20V-a/Istorie/Corint%20I/",
"55-3":	"https://manuale.edu.ro/manuale/Clasa%20a%20V-a/Istorie/Corint%20II/",
"55-4":	"https://manuale.edu.ro/manuale/Clasa%20a%20V-a/Istorie/CD%20Press/",
"55-5":	"https://manuale.edu.ro/manuale/Clasa%20a%20V-a/Istorie/ALL/",
"55-6":	"https://manuale.edu.ro/manuale/Clasa%20a%20V-a/Istorie/ARAMIS/",

"56-0"	: "https://manuale.edu.ro/manuale/Clasa%20a%20VI-a/Istorie/EDP/",

"57-0":	"https://manuale.edu.ro/manuale/Clasa%20a%20VII-a/Istorie/U0MgQVJUIEtMRVRUIFNS/#book/00",
"57-1":	"https://manuale.edu.ro/manuale/Clasa%20a%20VII-a/Istorie/U0MgQ0QgUFJFU1MgU1JM/",
"57-2":	"https://manuale.edu.ro/manuale/Clasa%20a%20VII-a/Istorie/U0MgQVJBTUlTIFBSSU5U/",

"65-0":	"https://manuale.edu.ro/manuale/Clasa%20a%20V-a/Limba%20moderna%20engleza/Art/",
"65-1":	"https://manuale.edu.ro/manuale/Clasa%20a%20V-a/Limba%20moderna%20engleza/Uniscan/",
"65-2":	"https://manuale.edu.ro/manuale/Clasa%20a%20V-a/Limba%20moderna%20engleza/Litera/",
"65-3":	"https://manuale.edu.ro/manuale/Clasa%20a%20V-a/Limba%20moderna%20engleza/UNISCAN1/",
"65-4":	"https://manuale.edu.ro/manuale/Clasa%20a%20V-a/Limba%20moderna%20engleza/ART1/",
"65-5":	"https://manuale.edu.ro/manuale/Clasa%20a%20V-a/Limba%20moderna%20engleza/Booklet/",
"65-6":	"https://manuale.edu.ro/manuale/Clasa%20a%20V-a/Limba%20moderna%20engleza/Litera1/",
"65-7":	"https://manuale.edu.ro/manuale/Clasa%20a%20V-a/Limba%20moderna%20engleza/CORINT/",

"66-0":	"https://manuale.edu.ro/manuale/Clasa%20a%20VI-a/Limba%20moderna%20engleza/SCARTKLETTSRL/",
"66-1":	"https://manuale.edu.ro/manuale/Clasa%20a%20VI-a/Limba%20moderna%20engleza/UNISCANGRUPEDUCA%C8%9AIONAL/",
"66-2":	"https://manuale.edu.ro/manuale/Clasa%20a%20VI-a/Limba%20moderna%20engleza/UNISCANGRUPEDUCA%C8%9AIONAL1/",
"66-3":	"https://manuale.edu.ro/manuale/Clasa%20a%20VI-a/Limba%20moderna%20engleza/GRUPEDITORIALLITERASRL/",
"66-4":	"https://manuale.edu.ro/manuale/Clasa%20a%20VI-a/Limba%20moderna%20engleza/GRUPEDITORIALLITERASRL1/",
"66-5":	"https://manuale.edu.ro/manuale/Clasa%20a%20VI-a/Limba%20moderna%20engleza/U0MgQVJUIEtMRVRUIFNS/",
"66-6":	"https://manuale.edu.ro/manuale/Clasa%20a%20VI-a/Limba%20moderna%20engleza/U0MgVU5JU0NBTiBHUlVQ/",

"67-0":	"https://manuale.edu.ro/manuale/Clasa%20a%20VII-a/Limba%20moderna%20engleza/SCARTKLETTSRL/",
"67-1":	"https://manuale.edu.ro/manuale/Clasa%20a%20VII-a/Limba%20moderna%20engleza/UNISCANGRUPEDUCA%C8%9AIONAL/",
"67-2":	"https://manuale.edu.ro/manuale/Clasa%20a%20VII-a/Limba%20moderna%20engleza/GRUPEDITORIALLITERASRL/",
"67-3":	"https://manuale.edu.ro/manuale/Clasa%20a%20VII-a/Limba%20moderna%20engleza/U0MgR1JVUCBFRElUT1JJ/",
"67-4":	"https://manuale.edu.ro/manuale/Clasa%20a%20VII-a/Limba%20moderna%20engleza/U0MgVU5JU0NBTiBHUlVQ/",
"67-5":	"https://manuale.edu.ro/manuale/Clasa%20a%20VII-a/Limba%20moderna%20engleza/U0MgQVJUIEtMRVRUIFNS/",
"67-6":	"https://manuale.edu.ro/manuale/Clasa%20a%20VII-a/Limba%20moderna%20engleza/U0MgVU5JU0NBTiBHUlVQ51/",
"67-7":	"https://manuale.edu.ro/manuale/Clasa%20a%20VII-a/Limba%20moderna%20engleza/U0MgQ0VOVFJVTCBERSBD/#/0",

"75-0":	"https://manuale.edu.ro/manuale/Clasa%20a%20V-a/Informatica%20si%20TIC/Sigma/",
"75-1":	"https://manuale.edu.ro/manuale/Clasa%20a%20V-a/Informatica%20si%20TIC/Corint/",
"75-2":	"https://manuale.edu.ro/manuale/Clasa%20a%20V-a/Informatica%20si%20TIC/Intuitext/",
"75-3":	"https://manuale.edu.ro/manuale/Clasa%20a%20V-a/Informatica%20si%20TIC/EDP/",
"75-4":	"https://manuale.edu.ro/manuale/Clasa%20a%20V-a/Informatica%20si%20TIC/Litera/",
"75-5":	"https://manuale.edu.ro/manuale/Clasa%20a%20V-a/Informatica%20si%20TIC/CD%20Press/",

"76-0":	"https://manuale.edu.ro/manuale/Clasa%20a%20VI-a/Informatica%20si%20TIC/EDP/",
"76-1": "https://manuale.edu.ro/manuale/Clasa%20a%20VI-a/Informatica%20si%20TIC/EDP2/",
"76-2":	"https://manuale.edu.ro/manuale/Clasa%20a%20VI-a/Informatica%20si%20TIC/EDP3/",


"77-0":	"https://manuale.edu.ro/manuale/Clasa%20a%20VII-a/Informatica%20si%20TIC/U0MgR1JVUCBFRElUT1JJ/",
"77-1":	"https://manuale.edu.ro/manuale/Clasa%20a%20VII-a/Informatica%20si%20TIC/U0MgSU5UVUlURVhUIFNS/",
"77-2":	"https://manuale.edu.ro/manuale/Clasa%20a%20VII-a/Informatica%20si%20TIC/U0MgRURJVFVSQSBESURB/",

}

const findBook = (element) => {

    console.log(element)
    return values[element];
}

export default findBook;